import React, { FunctionComponent } from "react"
import { Provider } from "react-redux"
import { AppState } from "../store/appState"
import configureStore from "../store/store"
import FrontPage from "./frontPage"

const preloadedState: Partial<AppState> = {
  playerHasFinished: { playerHasFinished: false},
  startPlayer: { startPlayer: false }
}

const store = configureStore(preloadedState);

const IndexPage: FunctionComponent = () => {

  return (
      <Provider store={store}>
        <FrontPage />
      </Provider>
    ) 
  }

export default IndexPage;